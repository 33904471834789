<!-- 销售海报 -->
<template>
    <div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
        <!-- <div class="title">销售海报</div> -->
        <div class="poster">
            <img src="../../assets/poster.jpg" alt="销售海报">
        </div>
    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>

export default {
    name: "customer_status_profile",
    props: ["xref", "type", "chartData"],
    emits: ["closeChart"],
    data() {
        return {
            loading: true,
            isShow: ''
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        createChart() {
            this.loading = false;
        },

        sizechange() {
            this.MyChart.resize();
        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

// .title {
//     height: 40px;
//     line-height: 40px;
//     font-size: 20px;
//     text-align: center;
//     color: #fff;
// }

.poster {
    img {
        width: 100%;
        height: 100%;
       
    }
}
</style>