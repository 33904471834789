<!-- 客户阶段 -->
<template>
    <div v-loading="loading" @click="resizedChange" ref="MyChartDom" class="my_tasks">
        <div class="my_tasks_title">客户阶段<span style="margin-left: 5px;">1/6</span></div>
        <div class="my_tasks_item_wrap" :style="{ left: num + 'px' }">
            <div class="item-row" v-for="(item, i) in list" :key="i">
                <div class="item-box" :style="{ border: '1px solid', borderColor: i === 1 ? 'red' : '#ccc' }">
                    <div class="children" v-for="val in item.children" :key="val">
                        <div class="content" :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">
                            {{ val.name }}
                            <span @click="goUrl(val.url,val.value)"
                                :style="{ color: parseInt(val.value) === 0 ? '#fff' : val.color }">{{ val.value }}</span>
                            <!-- <span @click="goUrls(val.url1)" v-if="val.value1" :style="{ color: val.color }">
                                /{{ val.value1 }}</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { customerStage } from "@/api/ChartsDataRequest";

export default {
    name: "terminated_customer",
    props: [
        "test"
    ],
    emits: ["closeChart"],
    data() {
        return {
            num: 0,
            loading: true,
            isShow: '',
            w: '100%',
            list: [
                {
                    children: [
                        {
                            name: '线索已归属：',
                            url: '/CRM/cluePool?tabs=3',
                            value: '',
                        },
                        {
                            name: '客户已归属：',
                            url: '/CRM/highSeas?tabs=3',
                            value: '',
                        },
                        {
                            name: '待拜访线索：',
                            url: '/CRM/cluePool?tabs=3&jump_type=1',
                            value: '',
                        },
                        {
                            name: '待拜访客户：',
                            url: '/CRM/highSeas?tabs=3&jump_type=3',
                            value: '',
                        },
                        {
                            name: '已转正式客户：',
                            url: '/CRM/officialCustomer?jump_type=5',
                            value: '',
                        },
                        {
                            name: '合同洽谈中：',
                            url: '/customerManage/customerInfo?jump_type=1',
                            value: '',
                        },
                        {
                            name: '已签订合同：',
                            url: '/customerManage/customerInfo?jump_type=2',
                            value: '',
                        },
                    ]
                },
                {
                    children: [
                      // 预警
                      {
                          name: '线索未拜访已预警(2天)：',
                          url: '/CRM/cluePool?tabs=3&jump_type=7',
                          value: '10',
                          color: '#f9b707',
                      },
                      {
                          name: '客户未拜访已预警(2天)：',
                          url: '/CRM/highSeas?tabs=3&jump_type=8',
                          value: '10',
                          color: '#f9b707',
                      },
                      {
                          name: '转正式已预警(7天)：',
                          url: '/CRM/officialCustomer?jump_type=9',
                          value: '10',
                          color: '#f9b707',
                      },
                      {
                          name: '合同洽谈已预警(7天)：',
                          url: '/customerManage/customerInfo?jump_type=4',
                          value: '',
                          color: '#f9b707'
                      },
                      // 超期
                      {
                          name: '线索未拜访已超期(7天)：',
                          url: '/CRM/cluePool?tabs=3&jump_type=2',
                          value: '',
                          color: '#FF0000'
                      },
                      {
                          name: '客户未拜访已超期(15天)：',
                          url: '/CRM/highSeas?tabs=3&jump_type=4',
                          value: '',
                          color: '#FF0000'
                      },
                      {
                          name: '转正式已超期(30天)：',
                          url: '/CRM/officialCustomer?jump_type=6',
                          value: '',
                          color: '#FF0000'
                      },
                      {
                          name: '合同洽谈已超期(30天)：',
                          url: '/customerManage/customerInfo?jump_type=3',
                          value: '',
                          color: '#FF0000'
                      }
                    ]
                }
            ],
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },

        // 图表创建
        createChart() {
            
            this.loading = false;
            customerStage().then((res) => {
                
                if (res.data.code == 200) {
                    this.list.forEach(item => {
                        item.children.forEach(v => {
                            if (v.name === '线索已归属：') {
                                v.value = res.data.data.clue_assign_cont
                            } else if (v.name === '客户已归属：') {
                                v.value = res.data.data.customer_assign_cont
                            } else if (v.name === '待拜访线索：') {
                                v.value = res.data.data.wait_visit_clue_cont
                            } else if (v.name === '待拜访客户：') {
                                v.value = res.data.data.wait_visit_customer_cont
                            } else if (v.name === '已转正式客户：') {
                                v.value = res.data.data.formal_customer_no_contract
                            } else if (v.name === '线索未拜访已超期(7天)：') {
                                v.value = res.data.data.delay_visit_clue_cont//超七天未拜访线索
                            } else if (v.name === '线索未拜访已预警(2天)：') {
                                v.value = res.data.data.warn_visit_clue_cont
                            } else if (v.name === '客户未拜访已超期(15天)：') {
                                v.value = res.data.data.delay_visit_customer_cont//超7天未拜访客户
                            } else if (v.name === '客户未拜访已预警(2天)：') {
                                v.value = res.data.data.warn_visit_customer_cont
                            } else if (v.name === '转正式已超期(30天)：') {
                                v.value = res.data.data.formal_customer_no_contract_delay
                            } else if (v.name === '转正式已预警(7天)：') {
                                v.value = res.data.data.formal_customer_no_contract_warn
                            } else if (v.name === '合同洽谈中：') {
                                v.value = res.data.data?.audited_no_contract_cont
                            } else if (v.name === '已签订合同：') {
                                v.value = res.data.data?.contract_customer_cont
                            } else if (v.name === '合同洽谈已超期(30天)：') {
                                v.value = res.data.data.audited_no_contract_cont_30day
                            } else if (v.name === '合同洽谈已预警(7天)：') {
                                v.value = res.data.data.audited_no_contract_cont_30day_warn
                            }
                        })
                    })

                } else {
                    console.log("Robot Data request fail");
                }
                this.loading = false;
            });
        },
        // 跳转
        goUrl(row, value) {
            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(row)

        }
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
    color: #fff;
    font-size: 18px;
    padding-top: 8px;
}

.my_tasks {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .my_tasks_title {
        font-size: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 68px;
        background-color: rgba(255, 255, 255, 0.08);
        color: #fff;

    }

    .my_tasks_item_wrap {
        width: v-bind(w);
        // height: 80%;
        height: 42%;
        text-align: left;
        align-items: center;
        transition: all 0.1s ease-in-out;
        position: relative;

        .item-row {
            width: 360px;
            height: 100%;
            margin-left: 20px;

            .item-box {
                width: 100%;
                // height: 98%;
                min-height:397px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #ccc;
                border-radius: 15px;
                font-size: 1.4rem;
                color: #fff;
                margin-top: 10px;

                .children {
                    display: block;

                    .content {
                        margin-top: 20px;

                        span {
                            color: #f9b707;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
