<!-- 交付-现场部署中 -->
<template>
    <!--  -->
    <div @click="sizechange" v-loading="loading" :style="{ width: '100%', height: '100%', overflow: 'hidden' }">
        <div class="title">交付-现场部署中</div>
        <div>
            <div class="graphic">
                <div class="cake" :style="{ width: '50%', height: '100%', overflow: 'hidden' }" ref="sceneCake">1</div>
                <div class="box" style="width:50%;">
                    <div class="verify"
                        @click.stop="goUrl('/deliveryManagement/workOrderLIst?node=现场部署&option=total_of_month', total_of_month, total_of_month_ids)">
                        <div class="verify-num">{{ total_of_month }}</div>
                        <div class="verify-text">本月：验证/备料中总数</div>
                    </div>
                    <div class="preview">
                        <div class="early"
                            @click.stop="goUrl('/deliveryManagement/workOrderLIst?node=现场部署&option=warning_of_month', warning_of_month, warning_of_month_ids)">
                            <div class="early-num">{{ warning_of_month }}</div>
                            <div class="early-text">本月</div>
                            <div class="early-text text">预警总数</div>
                        </div>
                        <div class="overdue"
                            @click.stop="goUrl('/deliveryManagement/workOrderLIst?node=现场部署&option=delayed_of_month', delayed_of_month, delayed_of_month_ids)">
                            <div class="overdue-num">{{ delayed_of_month }}</div>
                            <div class="overdue-text">本月</div>
                            <div class="overdue-text text">超期总数</div>
                        </div>
                    </div>
                </div>
            </div>
            <el-divider />
            <div class="item-row" v-for="(item, indedx) in list" :key="item">
                <div class="item">
                    <div class="item-left">
                        <div class="index">{{ indedx + 1 }}</div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="value" @click.stop="goUrl(item.url, item.value, item.id)">{{ item.value }}</div>
                    <div class="item-right">
                        <div class="overdues">
                            <div @click.stop="goUrl(item.urlFor, item.forecast, item.forId)"
                                :class="item.forecast === 0 ? 'forecast1' : 'forecast'">
                                预警：{{ item.forecast }}
                            </div>
                            <div @click.stop="goUrl(item.urlOve, item.overdue, item.oveId)"
                                :class="item.overdue === 0 ? 'overdue1' : 'overdue'">
                                超期：{{ item.overdue }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { node_scene_deploy, work_station_manage } from "@/api/ChartsDataRequest";

export default {
    name: "customer_status_profile",
    props: [],
    emits: ["closeChart"],
    data() {
        return {
            loading: false,
            total_of_month: '',//本月：验证/备料中总数
            total_of_month_ids: '',
            warning_of_month: '',//预警总数
            warning_of_month_ids: '',
            delayed_of_month: '',//超期总数
            delayed_of_month_ids: '',
            list: [
                {
                    name: '等待部署：',
                    value: 0,
                    url: '/customerManage/stationManagement',
                    id: '',
                    urlFor: '/customerManage/stationManagement',
                    forecast: 0,
                    forId: '',
                    urlOve: '/customerManage/stationManagement',
                    overdue: 0,
                    oveId: '',
                },
                {
                    name: '现场部署中：',
                    value: 0,
                    url: '/deliveryManagement/myTaskList?node=验证备料&option=scene_deploy',
                    id: '',
                    urlFor: '/deliveryManagement/myTaskList?node=验证备料&option=scene_deploy_warning',
                    forecast: 0,
                    forId: '',
                    urlOve: '/deliveryManagement/myTaskList?node=验证备料&option=scene_deploy_delayed',
                    overdue: 0,
                    oveId: '',
                }
            ]
        };
    },
    methods: {
        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        // 图表创建
        createChart() {
            this.ChartDom = this.$refs.sceneCake;
            this.MyChart = echarts.init(this.ChartDom, null);
            node_scene_deploy().then((res) => {

                if (res.data.code == 200) {
                    this.total_of_month = res.data.data.total_of_month || 0 //验证/备料中总数
                    this.warning_of_month = res.data.data.warning_of_month || 0 //预警总数
                    this.delayed_of_month = res.data.data.delayed_of_month || 0 //超期总数

                    // this.total_of_month_ids = res.data.data.total_of_month_ids
                    let ids = res.data.data.total_of_month_ids.split(',')
                    const warningIds = res.data.data.warning_of_month_ids.split(',').map(Number); // [2]
                    const delayedIds = res.data.data.delayed_of_month_ids.split(',').map(Number); // [3]
                    let id = ids.filter(id => {
                        return !warningIds.includes(Number(id)) && !delayedIds.includes(Number(id));
                    });
                    this.total_of_month_ids = id.join(',')

                    this.warning_of_month_ids = res.data.data.warning_of_month_ids
                    this.delayed_of_month_ids = res.data.data.delayed_of_month_ids

                    let normal = 0//无异常
                    normal = Number(this.total_of_month) - Number(this.warning_of_month) - Number(this.delayed_of_month)

                    let data = [
                        {
                            value: normal,
                            text: '无异常',
                            perCent: Number(this.total_of_month) > 0 ? Math.round((Number(this.normal) / Number(this.total_of_month)) * 100) + '%' : '0%',
                            itemStyle: { color: '#008980' }
                        },
                        {
                            value: res.data.data.warning_of_month || 0,
                            text: '预警',
                            perCent: Number(this.total_of_month) > 0 ? Math.round((Number(this.warning_of_month) / Number(this.total_of_month)) * 100) + '%' : '0%',
                            itemStyle: { color: '#FE7E24' }
                        },
                        {
                            value: res.data.data.delayed_of_month || 0,
                            text: '超期',
                            perCent: Number(this.total_of_month) > 0 ? Math.round((Number(this.delayed_of_month) / Number(this.total_of_month)) * 100) + '%' : '0%',
                            itemStyle: { color: '#FF0000' }
                        }
                    ]
                    const allZero = data.every(item => item.value === 0);
                    if (!allZero) {
                        data = data.filter(item => item.value !== 0);
                    }


                    const updateItemValues = (itemName, dataKey) => {
                        const dataValue = res.data.data[dataKey];
                        const warningKey = `${dataKey}_warning`;
                        const delayedKey = `${dataKey}_delayed`;
                        const id = `${dataKey}_ids`
                        const warId = `${dataKey}_warning_ids`;
                        const delId = `${dataKey}_delayed_ids`;

                        this.list.forEach(item => {
                            if (item.name === itemName) {
                                item.value = dataValue;
                                item.forecast = res.data.data[warningKey];
                                item.overdue = res.data.data[delayedKey];
                                item.id = res.data.data[id]
                                item.forId = res.data.data[warId]
                                item.oveId = res.data.data[delId]
                            }
                        });
                    };
                    updateItemValues('等待部署：', 'wait_to_deploy');
                    updateItemValues('现场部署中：', 'scene_deploy');

                    this.MyChart.setOption({
                        title: {
                            text: '',
                            left: 'center'
                        },
                        tooltip: {
                            show: false,
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                        },
                        series: [
                            {
                                name: 'Access From',
                                type: 'pie',
                                radius: '90%',
                                data,
                                // data: [
                                //     { value: 1048, text: '无异常', perCent: '10%', itemStyle: { color: '#008980' } },
                                //     { value: 735, text: '预警', perCent: '10%', itemStyle: { color: '#FE7E24' } },
                                //     { value: 580, text: '超期', perCent: '10%', itemStyle: { color: '#FF0000' } },
                                // ],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                },
                                label: {
                                    show: true, // 显示标签
                                    position: 'inside', // 在块内部显示
                                    formatter: function (params) {
                                        return [
                                            `${params.data.text}`,
                                            `${params.value}`,
                                            `${params.data.perCent}`
                                        ].join('\n'); // 使用换行符
                                        // return  params.value; // 显示当前块的数值
                                    },
                                    fontSize: 14 // 标签字体大小
                                }
                            }
                        ]
                    });
                    this.loading = false;
                }
            });
            setTimeout(() => {
                this.$nextTick(() => {
                    this.MyChart.resize();
                });
            }, 0);
        },

        // 跳转
        goUrl(row, value, id) {
            let url
            if (row.includes('现场部署')) {
                url = row + '&ids=' + id
            } else {
                url = row + '?ids=' + id
            }
            if (parseInt(value) !== 0) window.$wujie.props.method.propsMethod(url)
        },

        sizechange() {
            this.MyChart.resize();
        },
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

// .vue-chart-close {
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     top: 2px;
//     right: 2px;
//     background: url("../../assets/x.svg");
//     background-size: 18px 18px;
//     background-position: 100% 100%;
//     background-repeat: no-repeat;
//     background-origin: content-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
//     cursor: pointer;
// }

.title {
    height: 68px;
    line-height: 68px;
    font-size: 20px;
    text-align: center;
    /* background-color: rgba(255, 255, 255, 0.08); */
    color: #fff;
}

.graphic {
    width: 96%;
    height: 200px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;

    .box {
        margin-top: 18px;

        .verify {
            width: 90%;
            border-radius: 8px;
            background-color: #008980;
            cursor: pointer;

            .verify-num {
                font-size: 30PX;
                padding-top: 15px;
                line-height: 20PX;
                color: #F9B707;
            }

            .verify-text {
                padding: 5px 0;
                color: #fff;
                font-size: 14px;
            }
        }
    }

    .preview {
        margin-top: 20px;
        width: 90%;
        display: flex;
        justify-content: space-between;
        color: #fff;

        .early {
            width: 48%;
            border-radius: 8px;
            background-color: #FE7E24;
            cursor: pointer;

            .early-num {
                font-size: 24PX;
                padding-top: 10px;
            }

            .early-text {
                font-size: 14px;
            }

            .text {
                padding-bottom: 5px;
            }
        }

        .overdue {
            width: 48%;
            border-radius: 8px;
            background-color: #FF0000;
            cursor: pointer;

            .overdue-num {
                font-size: 24PX;
                padding-top: 10px;
            }

            .overdue-text {
                font-size: 14px;
            }

            .text {
                padding-bottom: 5px;
            }
        }
    }
}



:deep(.el-divider) {
    width: 96%;
    margin: 10px auto 10px;
    border-color: #005F59;
}


.item-row {
    padding: 0 10px;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #005F59;
    border-radius: 8px;
    color: #fff;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;


    .item-left {
        width: 42%;
        display: flex;
        align-items: center;

        .index {
            // width: 15px;
            padding: 0 2px;
            // background-color: #089187;
            background-color: #045A55;
            border-radius: 8px 0px 0px 8px;
        }

        .name {
            margin-left: 5px;
        }
    }

    .value {
        width: 10%;
        font-size: 20px;
        color: #F9B707;
        background-color: #005F59;
        cursor: pointer;
    }

    .item-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .overdues {
            display: flex;
            height: 30px;
            line-height: 30px;
            margin-right: 5px;


            .forecast {
                // width: 70px;
                padding: 0px 10px;
                background-color: #FE7E24;
                margin-right: 8px;
                border-radius: 30px;
                cursor: pointer;
            }

            .forecast1 {
                // width: 70px;
                color: #fff;
                border: 1px solid #ccc;
                padding: 0px 10px;
                margin-right: 8px;
                border-radius: 30px;
                cursor: pointer;
            }

            .overdue {
                // width: 70px;
                padding: 0px 10px;
                border-radius: 30px;
                background-color: #FF0000;
                cursor: pointer;
            }

            .overdue1 {
                // width: 70px;
                color: #fff;
                border: 1px solid #ccc;
                padding: 0px 10px;
                border-radius: 30px;
                cursor: pointer;
            }
        }
    }
}
</style>
