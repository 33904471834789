
// 设置看板的默认宽高及最小宽高
function setDefaultDimensions(newSelectedArr) {
    // 定义一个映射关系，方便扩展和管理
    const defaultDimensions = {
        deliverVerify: { w: 3, h: 15, minW: 3, minH: 15 },// 验证/备料 
        deliverDeploy: { w: 3, h: 10, minW: 3, minH: 10 },//交付-现场部署中
        deliverOperation: { w: 6, h: 16, minW: 6, minH: 16 },//交付-运营
        demandUnaccepted: { w: 4, h: 15, minW: 4, minH: 15 },//需求-需求发布未承接
        sale_notice: { w: 6, h: 11, minW: 6, minH: 11 }//销售代表特别补贴公告板
    };

    // 遍历每个看板项，设置默认宽高和最小宽高
    newSelectedArr.forEach(v => {
        const dimensions = defaultDimensions[v.chartBoxKey];

        // 如果有对应的尺寸设置，应用它
        if (dimensions) {
            v.w = dimensions.w;
            v.h = dimensions.h;
            v.minW = dimensions.minW;
            v.minH = dimensions.minH;
        }
    });
}
// 兼容笔记本，当拖拽宽度到了最小值拖拽值时(其实笔记本没到),会导致拖拽不回去，因此强制回到设置的值
function adjustDimensions(layout) {
    layout.forEach(v => {
      if (v.chartBoxKey === 'deliverOperation') { // 交付-运营
        if (v.w === 7) v.w = 6;
        if (v.h === 16) v.h = 15;
      } else if (v.chartBoxKey === 'deliverDeploy') { // 交付-现场部署中
        if (v.w === 4) v.w = 3;
        if (v.h === 11) v.h = 10;
      } else if (v.chartBoxKey === 'deliverVerify') { // 验证/备料
        if (v.w === 4) v.w = 3;
        if (v.h === 16) v.h = 15;
      } else if (v.chartBoxKey === 'demandUnaccepted') { // 需求-需求发布未承接
        if (v.w === 5) v.w = 4;
        if (v.h === 16) v.h = 15;
      } else if (v.chartBoxKey === 'sale_notice') { // 销售代表特别补贴公告板
        if (v.w === 7) v.w = 6;
        if (v.h === 12) v.h = 11;
      }
    });
  }

// 导出函数
export { setDefaultDimensions ,adjustDimensions};
